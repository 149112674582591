import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ImmigrationcompilationModule } from './immigrationcompilation/immigrationcompilation.module';
import { environment } from 'src/environments/environment';
import { MsalGuard } from '@azure/msal-angular';
import { CustomHttpInterceptor } from './core/interceptors/insert-auth-token-interceptor';
import { AuthGuard } from './core/guards/auth.guard';
// Kendo Controls
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { FloatingLabelModule, LabelModule } from '@progress/kendo-angular-label';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ExcelModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { IconsModule } from "@progress/kendo-angular-icons";
import { ListViewModule } from '@progress/kendo-angular-listview';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { ChartsModule } from '@progress/kendo-angular-charts';
import "hammerjs";
import { DAFHomeModule } from './DAF/modules/dafhome/dafhome.module';


export const protectedResourceMap: [string, string[]][] =
  [[environment.ApiUrl + 'values', [environment.APIScope]],
    [environment.GraphUri + 'v1.0/me', ['user.read']]];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 50,
      gap: 10
    }
  },
  behaviour: {
    autoHide: 4000
  }
};

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    BreadcrumbComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    // FormsModule,
    // ReactiveFormsModule,
    TextBoxModule,
    InputsModule,
    BrowserAnimationsModule,
    ImmigrationcompilationModule,
    NotifierModule.withConfig(customNotifierOptions),
    FloatingLabelModule,
    TreeViewModule,
    TreeListModule,
    DatePickerModule,
    DialogModule,
    ExcelModule,
    LayoutModule,
    InputsModule,
    LabelModule,
    IconsModule,
    TextBoxModule,
    FloatingLabelModule,
    MsalModule.forRoot({
      auth: {
        clientId: environment.FrontEndClientId,
        authority: environment.Authority,
        redirectUri: environment.RedirectUri,
        postLogoutRedirectUri: environment.RedirectUri,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      },
    },
      {
        popUp: false,
        consentScopes: [
          'user.read',
          'openid',
          'profile',
          environment.APIScope
        ],
        unprotectedResources: [],
        protectedResourceMap: protectedResourceMap,
        extraQueryParameters: {}
      }),
    RouterModule.forRoot([
    { path: 'daf', loadChildren: () => import('./DAF/modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
    { path: '', loadChildren: () => import('./immigrationcompilation/immigrationcompilation.module').then(m => m.ImmigrationcompilationModule), pathMatch: 'full', canActivate: [MsalGuard] },
    { path: 'dafdashboard', loadChildren: () => import('./DAF/modules/dafhome/dafhome.module').then(m => m.DAFHomeModule) }
], { relativeLinkResolution: 'legacy' }),
    ListViewModule,
    ChartsModule,
      DAFHomeModule,
    NavigationModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    }, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
