import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { User } from '../model/user';
import { UserService } from 'src/app/immigrationcompilation/usermanagement/service/user.service';
import { NgForm } from '@angular/forms';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DateTimePickerModule } from '@progress/kendo-angular-dateinputs/dist/es2015/datetimepicker/datetimepicker.module';
import { NotifierService } from 'angular-notifier';
import { InsertUser } from '../model/insertUser';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent implements OnInit {
  public selectedRoles: any = [];
  public HiddenSelectedRoles: any = [];
  @Input() user: User;
  @Input() 
  public set selectedRole(val: any) {
      if(val.length>0){
        this.enableRoles();
      this.updateRoles(val);
      }
      else{
        this.enableRoles();
      }
    };
  @Output() updateUserList = new EventEmitter<boolean>();
  @ViewChild('closeButton',  {static: false}) closeButton: ElementRef;
  @ViewChild('addcloseButton',  {static: false}) addcloseButton: ElementRef;
  @ViewChild('autocomplete' ,  {static: false}) autocomplete: any;
  @ViewChild('notification', {static: false}) customNotificationTmpl;
  errorMessage: string;
  public searchDetails: any;
  public pageSize = 10;
  public skip = 0;
  public gridState: DataStateChangeEvent = {
    skip: this.skip,
    take: this.pageSize
  };
  private notifier: NotifierService;
  public rolemaster: any[]=[];
  public editUserDetails: User;
  public addUserDetails: User;
  public userId: number;
  public addUserMail: string;
  public addUserName: string;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings= {
    singleSelection: false,
    idField: 'id',
    textField: 'roleName',
    enableCheckAll:false
  };
  showdropdown: boolean=false
  constructor(
    private userService: UserService,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService;
    }

  ngOnInit() {
  
    this.LoadRoleMaster();


    // console.log(this.user);
  }

  updateRoles(selectedRoleList){
    var array=this.rolemaster;
    this.rolemaster = [];
    let selectedRolesTemp = [];
    //selectedRole.forEach(element => {

    // var role= array.filter(item=>item.id==element.id);
    //  var restrictions=  role[0].restrictions.split(','); 
    //  restrictions.forEach(element => {
    //    array.forEach(elements => {
    //      if(elements.id==element){
    //        elements.isDisabled=true;
    //      }
    //    });
    //  });
    //});
    this.rolemaster=[...array];
    
    selectedRoleList.forEach(element => {
      if (array.find(x => x.id == element.id)) {
        selectedRolesTemp.push(element);
      }
      else {
        this.HiddenSelectedRoles.push(element);
      }
    });

    this.selectedRoles = selectedRolesTemp;
  }

  onItemSelect(item: any) {
    if(item.id==6 && this.selectedRoles.length>0){
      this.selectedRoles=this.selectedRoles.filter(item=>item.id==6);
    }
    var array=this.rolemaster;
    this.rolemaster=[];
    var roleData = array.filter(items => items.id == item.id);

    var restrctions=roleData[0].restrictions.split(',');
    restrctions.forEach(element => {
      array.forEach(elements => {
        if(elements.id==element){
          elements.isDisabled=true;
        }
      });
      
      
    });
    this.rolemaster=[...array];
  }
  onDeSelect(item: any) {
    var array=this.rolemaster;
    this.rolemaster=[];
    var roleData=array.filter(items=>items.id==item.id);
    var restrctions=roleData[0].restrictions.split(',');
    restrctions.forEach(element => {
      array.forEach(elements => {
        if(elements.id==element){
          elements.isDisabled=false;
        }
      });
      
      
    });
    this.rolemaster=[...array];
  }
  LoadRoleMaster() {
    
    this.rolemaster=[];
    this.userService.getRolesUrl().subscribe((roleresult: any[]) => {
      var roles = roleresult;
      var userroles =JSON.parse(localStorage.getItem("isSuperAdmin"));
      if(userroles.includes('SuperAdmin')){
        roles.forEach(element => {
          this.rolemaster.push({'id':element.roleId,'roleName':element.roleName,'isDisabled':false,'restrictions':element.roleRestrictions});
        });
      }
      else{
      if(userroles.includes('CourierPackage-Admin')){
        roles.forEach(element => {
          if(element.appName=="CourierPackage"){
          this.rolemaster.push({'id':element.roleId,'roleName':element.roleName,'isDisabled':false,'restrictions':element.roleRestrictions});
          }
        });
      }
      if(userroles.includes('ImmigrationCompilation-Admin')){
        roles.forEach(element => {
          if(element.appName=="ImmigrationCompilation"){
          this.rolemaster.push({'id':element.roleId,'roleName':element.roleName,'isDisabled':false,'restrictions':element.roleRestrictions});
          }
        });
      }
      if(userroles.includes('LCAComplianceExternal')){
        roles.forEach(element => {
          if(element.appName=="LCACompliance"){
          this.rolemaster.push({'id':element.roleId,'roleName':element.roleName,'isDisabled':false,'restrictions':element.roleRestrictions});
          }
        });
      }
      if(userroles.includes('LCAComplianceInternal')){
        roles.forEach(element => {
          if(element.appName=="LCACompliance"){
          this.rolemaster.push({'id':element.roleId,'roleName':element.roleName,'isDisabled':false,'restrictions':element.roleRestrictions});
          }
        });
        }
        if (userroles.includes('LCACompliance-Admin')) {
          roles.forEach(element => {
            if (element.appName == "LCACompliance") {
              this.rolemaster.push({ 'id': element.roleId, 'roleName': element.roleName, 'isDisabled': false, 'restrictions': element.roleRestrictions });
            }
          });
        }
    }

      
    this.showdropdown=true;
    
   }, error => {console.error(error); console.log('error'); });
 }
 closeForm() {
  this.enableRoles();
   this.selectedRoles=[];
  const closeButton: HTMLElement = this.closeButton
    .nativeElement as HTMLElement;
  closeButton.click();
}

enableRoles(){
  
  if(this.rolemaster.length>0){
    var arrays = this.rolemaster;
    this.rolemaster=[];
  arrays.forEach(element => {
    element.isDisabled=false;
  });
  this.rolemaster=[...arrays];
}
}

closemodal(){
  this.enableRoles();
  this.selectedRoles=[];
}








addcloseForm() {
  this.addUserMail = '';
  this.addUserName = '';
  this.autocomplete.reset();
  this.enableRoles();
  this.selectedRoles=[];
  const addcloseButton: HTMLElement = this.addcloseButton
    .nativeElement as HTMLElement;
  addcloseButton.click();
}
editUser(editUserForm: NgForm) {
  this.userId = this.user.userId;
  this.editUserDetails = editUserForm.value;
  this.editUserDetails.userId = this.userId;
  this.HiddenSelectedRoles.forEach(element => {
    this.selectedRoles.push(element);
  });
  this.userService.editUser(this.userId, this.selectedRoles).subscribe((data: any) => {
    this.closeForm();
    this.updateUserList.emit(true);
  },
  (error) => {
   this.notifier.notify('error', 'Error occured while updating the user details');
  }
  );
}
addUser(addUserForm: NgForm) {
  this.addUserDetails = addUserForm.value;
  this.addUserDetails.email = addUserForm.controls['email'].value;
  this.addUserDetails.name = addUserForm.controls['name'].value;
  var users=new InsertUser();
  
  users.name=this.addUserDetails.name;
  users.email=this.addUserDetails.email;
  users.roles = this.selectedRoles;

  this.userService.addUser(users).subscribe((data: any) => {
   if (data.message.includes('exists')) {
    this.notifier.notify('error', 'User already exists');
    this.errorMessage = 'User details already exists';
    addUserForm.resetForm();
    this.autocomplete.reset();
   } else {
    this.addUserMail = '';
    this.addUserName = '';
    addUserForm.resetForm();
    this.autocomplete.reset();
    this.addcloseForm();
    this.updateUserList.emit(true);
   }
  },
  (error) => {
    this.notifier.notify('error', 'Error occured while saving the user details');
  }
  );
}
  searchUserDetails(email: any) {
    this.userService.searchUserDetails(email.target.value.trim()).subscribe((data: any) => {
    this.searchDetails = data.value;
    },
    (error) => {
      this.notifier.notify('error', 'Error occured while searching the user details');
    });
  }
  selectAddUserDetails(email: any) {
    this.addUserMail = email.mail;
    this.addUserName = email.displayName;
    this.autocomplete.reset();
}
}

