import { Component, OnInit } from '@angular/core';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl } from '@angular/forms';
import { process } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard/service/dashboard.service';
import { CommonService } from 'src/app/service/common.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public caseList: any[];
  dateFilterModel: DateFilterModel;
  await: number;
  public isPageReady = true;
  awaitCompilation: number;
  lawClerk: number;
  fail: number;
  submit: number;
  reject: number;
  engagement;
  public engagementList: any = [{ 'EngagementId': '1', 'EngagementName': 'Wright Service Corportation' },
  { 'EngagementId': '2', 'EngagementName': 'EY GTH India' }];
  fromReportDate: any;
  toReportDate:any;
  constructor(private router: Router, private dashboardService: DashboardService,private commonService: CommonService) {
    this.dateFilterModel = new DateFilterModel(new Date(2020, 1, 1), new Date());
  }

  ngOnInit() {
    this.getReportDate();
   
  }

  getReportDate(){

    this.commonService.currentData
        .subscribe((data: any) => {
          // Navigates to accessdenied component if user doesn't exist
         this.fromReportDate=data.fromDate;
         this.toReportDate=data.toDate;
         this.dateFilterModel = new DateFilterModel(new Date(this.fromReportDate),new Date(this.toReportDate));
         this.getDashboardCount();
        },
        (error) => {
        });
  }

  filterCaseList(){
    this.commonService.updateDate({fromDate:this.dateFilterModel.startDate.toDateString(),toDate:this.dateFilterModel.endDate.toDateString()});
  }

  getDashboardCount() {
    this.isPageReady = false;
    this.dashboardService.getDashboardCount(this.dateFilterModel.startDate.toDateString(), this.dateFilterModel.endDate.toDateString(), this.engagement).subscribe((data: any) => {
      // Binds the data to grid
      this.caseList = data;
      this.await = data.awaitDocExtract;
      this.awaitCompilation = data.awaitCompilation;
      this.lawClerk = data.awaitLawClerk;
      this.fail = data.compilationFailed;
      this.submit = data.submittedToAttorney;
      this.reject = data.compilationRejected;
      this.isPageReady = true;
      console.log(this.caseList);
    },
      (error) => {
        this.isPageReady = true;
        // this.notifier.notify('error', 'Error occured while getting data from server');
      }
    );
  }
  docExtraction() {
    this.router.navigate(['/awaitdocextract']);
  }
  awaitCompile() {
    this.router.navigate(['/awaitcompilation']);
  }
  compilationFailed() {
    this.router.navigate(['/compilationfailed']);
  }
  awaitLawClerkReview() {
    this.router.navigate(['/awaitlawclerkreview']);
  }
  submittedToLawyer() {
    this.router.navigate(['/submittedtolawyer']);
  }
  compilationRejected() {
    this.router.navigate(['/compilationrejected']);
  }

  resetDashboard(){
    this.commonService.resetDate.subscribe(data=>{
      this.commonService.updateDate({fromDate:data,toDate:data});
    });
  }

  getCaseCountByEngagement(id: any) {
    if (id == 1) {
      this.await = 15;
      this.awaitCompilation = 40;
      this.lawClerk = 10;
      this.fail = 1;
      this.submit = 15;
      this.reject = 0;
    }
    if (id == 2) {
      this.await = 25;
      this.awaitCompilation = 30;
      this.lawClerk = 5;
      this.fail = 0;
      this.submit = 5;
      this.reject = 4;
    }
    if (id == 0) {
      this.await = 60;
      this.awaitCompilation = 20;
      this.lawClerk = 8;
      this.fail = 2;
      this.submit = 5;
      this.reject = 3;
    }
  }
}
export class DateFilterModel {
  public startDate: Date;
  public endDate: Date;
  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
