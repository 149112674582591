import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    roles=[];
    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      let ret = false;
      this.roles=[];
      var roles =JSON.parse(localStorage.getItem("isSuperAdmin"));
        if(route.routeConfig.path=="dashboard"){
            this.roles=roles.filter(item=>{ return item=="ImmigrationCompilation-Attorney"||item=="ImmigrationCompilation-Admin"||item=="ImmigrationCompilation-Law Clerk"||item=="SuperAdmin";})
            if(this.roles.length>0){
                ret=true;
            }
            else if(roles.includes('CourierPackage-Admin')||roles.includes('CourierPackage-User')){
              
                this.router.navigate(['/courierpackage']);

            }
            else{
                this.router.navigate(['/accessdenied']);
            }

            
        }
        //else if(route.routeConfig.path=="awaitdocextract"||route.routeConfig.path=="awaitcompilation"||route.routeConfig.path=="compilationfailed"||route.routeConfig.path=="awaitlawclerkreview"||route.routeConfig.path=="submittedtolawyer"||route.routeConfig.path=="compilationrejected"||route.routeConfig.path=="detailedview/:parentBnfId/:applicantType/:requestType/:caseType/:insZoomId/:isLast"){
        else if (route.routeConfig.path == "awaitdocextract" || route.routeConfig.path == "awaitcompilation" || route.routeConfig.path == "compilationfailed" || route.routeConfig.path == "awaitlawclerkreview" || route.routeConfig.path == "submittedtolawyer" || route.routeConfig.path == "compilationrejected" || route.routeConfig.path == "detailedview/:Id/:isLast") {
            this.roles=roles.filter(item=>{ return item=="ImmigrationCompilation-Attorney"||item=="ImmigrationCompilation-Admin"||item=="ImmigrationCompilation-Law Clerk"||item=="SuperAdmin";})
            if(this.roles.length>0){
                ret=true;
            }
            else{
                this.router.navigate(['/accessdenied']);
            }
        }
        else if (route.routeConfig.path == "courierpackage" || route.routeConfig.path == "courierpackageSuccess" || route.routeConfig.path == "courierpackageFailure" || route.routeConfig.path == "courierpackagePendings" || route.routeConfig.path == "courierpackageAccessFailure"){
            this.roles=roles.filter(item=>{ return item=="CourierPackage-Admin"||item=="CourierPackage-User"||item=="SuperAdmin";})
            if(this.roles.length>0){
                ret=true;
            }
            else{
                this.router.navigate(['/accessdenied']);
            }
        }
        else if(route.routeConfig.path=="lawyer-management"){
            this.roles=roles.filter(item=>{ return item=="CourierPackage-Admin"||item=="SuperAdmin";})
            if(this.roles.length>0){
                ret=true;
            }
            else{
                this.router.navigate(['/accessdenied']);
            }
        }
        else if(route.routeConfig.path=="user-management"){
          this.roles = roles.filter(item => { return item == "ImmigrationCompilation-Admin" || item == "SuperAdmin" || item == "CourierPackage-Admin" || item == "LCACompliance-Admin"|| item=="DigitalAssessmentForm-Admin";})
            if(this.roles.length>0){
                ret=true;
            }
            else{
                this.router.navigate(['/accessdenied']);
            }
        }
        //const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        return ret;
    }
}
