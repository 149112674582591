import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ExportParams, PagingParams } from '../modules/dashboard/models/filterParams.model';
import { FormGroup } from '@angular/forms';
import { filingStratergyDetails } from '../Models/FilingStratergy';
import { dafAppConstants } from '../Models/applicationConstants';
import { WorkSitewizards } from '../Models/websiteWizard';
import { WorkExperience } from '../Models/WorkExperience';

@Injectable({
  providedIn: 'root'
})
export class DafService {
  private dataSource = new BehaviorSubject({emailId: 'Ashish.Kudva@ey.com',id:1});
  currentData = this.dataSource.asObservable();

  private dateSources = new BehaviorSubject( {fromDate:new Date(),toDate:new Date()});
  currentDates = this.dateSources.asObservable();

  private resetDates = new BehaviorSubject( new Date());
resetDate = this.resetDates.asObservable();
  mySubject : BehaviorSubject<any> = new BehaviorSubject<any>([]);
  addedLCAData=this.mySubject.asObservable();
  
  constructor(private _http: HttpClient) { }
  
  getCasesByStatus(statusus: string, caseType: string, startDate: any, endDate: any, filterParams: ExportParams) {
    var status=encodeURIComponent(statusus);
    //filterParams["status"]= this.getSessionStorage(dafAppConstants.sessionStorageVariables.currentStatus);
    //filterParams["caseType"]= this.getSessionStorage(dafAppConstants.sessionStorageVariables.currentCaseType);
    //return this._http.post<any>(environment.ApiUrl + 'daf/GetCasesByStatus', filterParams, {});
    return this._http.post<any>(environment.ApiUrl + 'daf/GetCasesByStatus?status=' + status + '&caseType=' + caseType + '&startDate=' + startDate + '&endDate=' + endDate, filterParams, {});
  }


  GetCaseSummary() {
    return this._http.get<any>(environment.ApiUrl + 'daf/GetCaseSummary');
  }

  GetCaseAssessorSummary(startDate, endDate) {
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    return this._http.get<any>(environment.ApiUrl + 'daf/GetAssessmentSummary', { params: params });
  }

  public updateDate(data: any) {
    this.dateSources.next(data);
  }

  public setresetDate(data: any) {
    this.resetDates.next(data);
  }

  GetCaseSummaryDashboard(startDate, endDate) {
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate );
    return this._http.get<any>(environment.ApiUrl + 'daf/GetCaseSummaryDashboard', { params: params });
  }

  getCasesById(caseId:number) {
    return this._http.get<any>(environment.ApiUrl + 'daf/GetCasesByCaseId?caseId='+caseId);
  }

  getCaseRecieptDetails(caseId:number) {
    return this._http.get<any>(environment.ApiUrl + 'daf/GetCaseRecieptDetails?caseId='+caseId);
  }
  GetIndividualBio(caseId:number) {
    return this._http.get<any>(environment.ApiUrl + 'daf/GetIndividualBioInfoByCaseId?caseId='+caseId);
  }

  GetPrimaryWorksiteAddress(caseId:number) {
    return this._http.get<any>(environment.ApiUrl + 'daf/GetPrimaryWorksiteAddress?caseId='+caseId);
  }

  GetFileHistoryInfo(caseSummaryId: number) {
    return this._http.get<any>(environment.ApiUrl + 'daf/GetFileHistoryInfo?caseSummaryId=' + caseSummaryId);
  }

  GetServiceCodes() {
    return this._http.get<any>(environment.ApiUrl + 'daf/GetServiceCode');
  }

  searchSupportUserDetails(email: string) {
    return this._http.get<any>(environment.GraphUri + 'v1.0/groups/6bd64b1b-3ab3-467a-8682-839c6b951ac5/members/microsoft.graph.user?$count=true&$search="mail:' + email + '" OR "displayName:' + email +'"');
  }

  saveCaseSummary(caseSummary: any,dob : any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/SaveCaseSummary?dob=' + dob, caseSummary, {});
  }

  saveIndividualBioInfo(indBioInfo: any, ped: any, iExpDate: any, visaExpDate: any , h1L1statusStartDate: any, h1L1statusToDate: any, dateFrom: any, dateTo: any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/SaveIndividualBiographicalInfo?peddate=' + ped + '&iExpDate=' + iExpDate + '&visaExpDate=' + visaExpDate + '&h1L1statusStartDate=' + h1L1statusStartDate + '&h1L1statusToDate=' + h1L1statusToDate + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo , indBioInfo, {});
  }

  savePrimaryWorksiteAddress(worksiteAddress: any, lcaValidityFrom: any, lcaValidityTo: any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/SavePrimaryWorksiteAddress?lcaValidityFrom=' + lcaValidityFrom + '&lcaValidityTo=' + lcaValidityTo, worksiteAddress, {});
  }

  saveJobDetailsWizard(worksiteAddress: any,caseSummaryId:any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/InsertJobTitleWizardData?caseSummaryId=' + caseSummaryId, worksiteAddress, {});
  }

  UpdateCaseStatus(caseId: any,caseStatus:any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/UpdateCaseStatus?caseSummaryId=' + caseId+'&caseStatus=' + caseStatus, {});
  }

  GetDAFValidation(caseId:any,caseStatus:any){
    return this._http.get<any>(environment.ApiUrl + 'daf/GetDAFValidation?caseSummaryId=' + caseId+'&moduleName=' + caseStatus.toString(), {});
  }

  saveAddressWizard(caseSummaryId:any,worksiteAddress: any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/InsertAddressWizardData?caseSummaryId=' + caseSummaryId, worksiteAddress, {});
  }

  saveLCAInfoWizard(caseSummaryId:any,worksiteAddress: any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/InsertLCAWizardData', worksiteAddress, {});
  }
  

  disableEnableAllFormControls(form:FormGroup,controlsToExclude:Array<string>=[],disable:boolean=true){
    try{
      Object.keys(form.controls).forEach(key => {
        const abstractControl = form.get(key);
        if (abstractControl instanceof FormGroup) {
          this.disableEnableAllFormControls(abstractControl,controlsToExclude, disable);
        }
        else{
          if(disable)
          {
            if(!controlsToExclude.includes(key)){
              form.controls[key].disable();
            }
          }
          else{
            if(!controlsToExclude.includes(key)){
              form.controls[key].enable();
            }
          }
        }
      });
    }
    catch(err){
      console.log(err);
    }
  }

  disableEnableFormControl(form:FormGroup,controlsArray:Array<string>,disable:boolean){
    try{
      controlsArray.forEach(key => {
        if(disable){
          form.controls[key].disable();
        }
        else{
          form.controls[key].enable();
        }
      });
    }
    catch(err){
      console.log(err);
    }
  }

  getStates(){
    try{
      return this._http.get<any>(`${environment.ApiUrl}daf/GetStates`);
    }
    catch(err){
      console.log(err);
    }
  }

  getOfficeAddress() {
    try {
      return this._http.get<any>(`${environment.ApiUrl}daf/GetOfficeAddresses`);
    }
    catch (err) {
      console.log(err);
    }
  }

  getImmigrationStatus(){
    try{
      return this._http.get<any>(`${environment.ApiUrl}daf/getImmigrationStatus`)
    }
    catch(err){
      console.log(err)
    }
  }

  getCountriesCitizenship(){
    try{
      return this._http.get<any>(`${environment.ApiUrl}daf/GetCountries`)
    }
    catch(err){
      console.log(err)
    }
  }

  getZipCodeByState(state:string){
    try{
      return this._http.get<any>(`${environment.ApiUrl}LCAData/GetZipcodes`);
    }
    catch(err){
      console.log(err);
    }
  }

  getJobPositionDetailsByCaseSummaryId(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetJobPositionDetailsByCaseSummaryId?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getCaseFeesByCaseSummaryId(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetCaseFeesByCaseSummaryId?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getFilingStratergyDetailsByCaseSummaryId(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetFilingStratergyDetailsByCaseSummaryId?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  saveJobPositionDetails(caseSummaryId: number, I129FromDate: any, I129ToDate : any, jobPositionDetails: any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/SaveJobPositionDetails?caseSummaryId=' + caseSummaryId + '&I129FromDate=' + I129FromDate + '&I129ToDate=' + I129ToDate, jobPositionDetails, {});
  }
  saveCaseFeesDetails(caseSummaryId: number, CaseFeesDetails: any, notes: any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/SaveCaseFeesByCaseSummaryId?caseSummaryId=' + caseSummaryId + '&notes=' + notes, CaseFeesDetails, {});
  }

  saveFilingStratergyDetails(caseSummaryId: number, filingstartergyDetails: filingStratergyDetails) {
    return this._http.post<any>(environment.ApiUrl + 'daf/SaveFilingStratergy?caseSummaryId=' + caseSummaryId, filingstartergyDetails, {});
  }

  savePrimaryWorkSiteItinerary(caseSummaryId: number, worksiteDetails: WorkSitewizards[]) {
    return this._http.post<any>(environment.ApiUrl + 'daf/InsertWorkSiteIterinary?caseSummaryId=' + caseSummaryId, worksiteDetails, {});
  }

  saveWorkExperienceDetails(caseSummaryId: number, worksiteDetails: WorkExperience[]) {
    return this._http.post<any>(environment.ApiUrl + 'daf/InsertWorkExperience?caseSummaryId=' + caseSummaryId, worksiteDetails, {});
  }

  updateWorkExperienceDetails(caseSummaryId: number, worksiteDetails: WorkExperience[]) {
    return this._http.post<any>(environment.ApiUrl + 'daf/UpdateWorkExperience?caseSummaryId=' + caseSummaryId, worksiteDetails, {});
  }

  UpdateSupportingmemberByCaseIds(CaseIds: string[], Memberemail: string) {
    return this._http.post<any>(environment.ApiUrl + 'daf/UpdateSupportingmemberByCaseIds?CaseIds=' + CaseIds + '&Memberemail=' + Memberemail, {});
  }

  deleteWorkExperienceDetails(workExperienceId: Number) {
    return this._http.delete<any>(environment.ApiUrl + 'daf/DeleteWorkExperience?workExperienceId=' + workExperienceId);
  }

  getprimaryworkSiteItineraryDetails(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetWorkSiteItineraryDetails?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getWorkExperienceDetails(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetWorkExperienceDetails?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getetONETOCSSDetails() {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetONETOCSSDetails');
    }
    catch (err) {
      console.log(err)
    }
  }
  getLCAAssessmentDetails(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetLCAAssessmentDetails?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getjobTitleInfos(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetJobTitleDetails?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getJobTitleInformation(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetJobTitleWizardInformation?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getAddressWizardInformation(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetAddressWizardInformation?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getLCAWizardInformation(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetLCAWizardInformation?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getLCAInformation(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetLCAInformation?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getDAFStates() {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetStates');
    }
    catch (err) {
      console.log(err)
    }
  }

  getDotCodeBySOCCd(socCode) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetDotCodeBySOCCode?socCode=' + socCode);
    }
    catch (err) {
      console.log(err)
    }
  }

  getZipcodeByState(stateId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetZipcodeByState?stateId=' + stateId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getCountyByState(stateId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetCountyByState?stateId=' + stateId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getCityByZipcode(zipCodeId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetCitiesByZipcode?zipCodeId=' + zipCodeId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getGeographyByState(stateId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetGeographyByState?stateId=' + stateId);
    }
    catch (err) {
      console.log(err)
    }
  }

  GetCountyByGeography(stateId: number, geographyId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetCountyByGeography?stateId=' + stateId + '&geographyId=' + geographyId);
    }
    catch (err) {
      console.log(err)
    }
  }

  GetGeographyByCounty(stateId: number, countyId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetGeographyByCounty?stateId=' + stateId + '&countyId=' + countyId);
    }
    catch (err) {
      console.log(err)
    }
  }

  GetCaseManagers() {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetCaseManagers');
    }
    catch (err) {
      console.log(err)
    }
  }

  GetAttorneys() {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetCaseAttorneys');
    }
    catch (err) {
      console.log(err)
    }
  }

  getCountyAndCityInfoByZipCode(zipCodeId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetCountyAndCityInfoByZipCode?stateId=' + zipCodeId);
    }
    catch (err) {
      console.log(err)
    }
  }

  saveLCAAssessmentDetails(caseSummaryId: number, lcaValidityFrom : any, lcaValidityTo : any, lcaAssessmentDetails: any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/SaveLCAAssessmentDetails?caseSummaryId=' + caseSummaryId + '&LCAValidityFrom=' + lcaValidityFrom + '&LCAValidityTo=' + lcaValidityTo , lcaAssessmentDetails, {});
  }

  getLCAPrevailingwage(caseSummaryId: number, onetCode: string) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetLCAPrevailingwage?caseSummaryId=' + caseSummaryId + '&onetCode=' + onetCode);
    }
    catch (err) {
      console.log(err)
    }
  }

  getFLCWage(geographyId: number, socCode: string, wageLevel: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetFLCWage?geographyId=' + geographyId + '&socCode=' + socCode + '&wageLevel=' + wageLevel);
    }
    catch (err) {
      console.log(err)
    }
  }

  getSpouseAndDependentDetails(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetSpouseAndDependentDetails?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getSpecialityDegreeDetails(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/SpeDegAssInfo?caseId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getDocumentChecklistDetails(caseSummaryId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetDocumentChecklistDetails?caseSummaryId=' + caseSummaryId);
    }
    catch (err) {
      console.log(err)
    }
  }

  saveSpouseAndDependentDetails(caseSummaryId: number, spouseAndDependentDetail: any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/SaveSpouseAndDependentDetails?caseSummaryId=' + caseSummaryId, spouseAndDependentDetail, {});
  }

  SaveSpecialityDegreeDetails(caseSummaryId: number, specialitydegreeDetails: any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/SaveSpecialityDegreeDetails?caseSummaryId=' + caseSummaryId, specialitydegreeDetails, {});
  }
  
  saveDocumentChecklistDetails(caseSummaryId: number, documentchecklist: any) {
    return this._http.post<any>(environment.ApiUrl + 'daf/SaveDocumentChecklistDetails?caseSummaryId=' + caseSummaryId, documentchecklist, {});
  }
  


  setSessionStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getSessionStorage(key: string) {
    return sessionStorage.getItem(key);
  }
  getOfficeInfoById(officeId: number) {
    try {
      return this._http.get<any>(environment.ApiUrl + 'daf/GetOfficeAddressesById?officeId=' + officeId);
    }
    catch (err) {
      console.log(err)
    }
  }

  getDAFStatus() {
    try {
      return this._http.get<any>(`${environment.ApiUrl}daf/GetDAFStatus`)
    }
    catch (err) {
      console.log(err)
    }
  }

  downloadG28Form(caseSummaryId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    };

    return this._http.get<any>(`${environment.ApiUrl}daf/DownloadG28Form?caseSummaryId=` + caseSummaryId, httpOptions);
  }

  GetFilingVenue() {
    return this._http.get<any>(environment.ApiUrl + 'daf/GetFilingVenue');
  }
}

