import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CheckUser } from '../usermanagement/model/checkUser';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  public items: any = [

    { title: 'Courier Package', col: 1, body: 'Courier Package', avatar: 'settings', redirectUrl: "courierpackage" },
    { title: 'Immigration Compilation', col: 2, body: 'Immigration Compilation', avatar: 'dashboard', redirectUrl: "dashboard" },
    //{ title: 'Digital Assessment Form', col: 2, body: 'Digital Assessment form', avatar: 'home', redirectUrl: "" },
    //{ title: 'H-1B Compliance / New work site request', col: 1, body: 'H1B Compliance / New work site request', avatar: 'school', redirectUrl: "lcaHomes" },
    //{ title: 'H-1B Compliance Internal Tracker', col: 3, body: 'H1B Compliance Internal Tracker', avatar: 'school', redirectUrl: "internalLca" },
    //{ title: 'LCA NWS External Replicator', col: 1, body: 'LCA NWS External Replicator',avatar:'school',redirectUrl:"lcaHome"  }
    
    //{ title: 'OOE', col: 3, body: 'OOE',avatar:'event',redirectUrl:""  }
];
profile;
email;
isSuperAdmin=[];
AdminRoles=[];
classes="";
photo: any = "../../assets/images/user.jpg";
public isPageReady=true;

  constructor(private http: HttpClient, private commonService: CommonService, private router: Router, private authService: MsalService) {
    this.profile = authService.getAccount().name;
    this.email = authService.getAccount().userName;
   }


  ngOnInit() {
    this.getUserRole(this.email);
    this.getAvatar();
  }

  redirectTo(value:any){
      if (value == "dashboard" || value == "courierpackage" || value == "internalLca" || value == "daf/dashboard"){
    this.router.navigate(['/'+value]);

    }
    else if(value=="lcaHome"){
      this.router.navigate(['/lcaHome'], { queryParams: { lcaType: 'replicator' } })
    }
    else if(value=="lcaHomes"){
      this.router.navigate(['/lcaHome'], { queryParams: { lcaType: 'internal' } })
    }
    else if(value!=""){
      window.open(value, "_blank");
    }
  }

  getAvatar() {
    this.http.get(environment.GraphUri + 'v1.0/users/' + this.email + '/photos/48x48/$value')
      .subscribe((photo: any) => {
        this.createImageFromBlob(photo);
      },
      () => {
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      this.photo = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  public listItems: Array<string> = [
    "X-Small",
    "Small",
    "Medium",
    "Large",
    "X-Large",
    "2X-Large",
  ];

  getUserRole(email) {
    this.isPageReady=false;
    var user=new CheckUser();
    user.email=email;
    // Gets user role from api using email
      this.commonService.getUserRoleByEmail(user.email)
        .subscribe((data: any) => {
          // Navigates to accessdenied component if user doesn't exist
          if (data.length==0) {
            this.router.navigate(['/accessdenied']);

          } else {
          
            var roleArray = data;
            this.isSuperAdmin = roleArray.map(a => a.appName);
            this.AdminRoles = roleArray.map(a => a.roleName);

            if(this.isSuperAdmin.includes('SuperAdmin'))
            {
              this.items.push({ title: 'Digital Assessment Form', col: 2, body: 'Digital Assessment form', avatar: 'home', redirectUrl: "daf/dashboard" });
              this.items.push({ title: 'LCA NWS External Replicator', col: 1, body: 'LCA NWS External Replicator', avatar: 'school', redirectUrl: "lcaHome" });
              this.items.push({ title: 'H-1B Compliance Internal Tracker', col: 3, body: 'H1B Compliance Internal Tracker', avatar: 'school', redirectUrl: "internalLca" });
              this.items.push({ title: 'H-1B Compliance / New work site request', col: 1, body: 'H1B Compliance / New work site request', avatar: 'school', redirectUrl: "lcaHomes" });
              
            }
            else{
              if(!this.isSuperAdmin.includes('CourierPackage')){
                this.items=this.items.filter(item=>item.title!="Courier Package");
              }
             if(!this.isSuperAdmin.includes('ImmigrationCompilation')){
              this.items=this.items.filter(item=>item.title!="Immigration Compilation");
             }
       
             if(this.isSuperAdmin.includes('LCACompliance')){
              this.items=this.items.filter(item=>item.title!="LCA NWS External Replicator");
               this.items = this.items.filter(item => item.title !="H-1B Compliance Internal Tracker");
               this.items = this.items.filter(item => item.title != "H-1B Compliance / New work site request");

             }
             //if(this.isSuperAdmin.includes('LCACompliance')&&!this.AdminRoles.includes('LCAComplianceExternal')&&!this.AdminRoles.includes('LCACompliance-Admin')&&!this.AdminRoles.includes('LCAComplianceExternalReplicator')){
             // this.items=this.items.filter(item=>item.title!="LCA NWS External Replicator");
             // }
              if (this.isSuperAdmin.includes('LCACompliance') && this.AdminRoles.includes('LCACompliance-Admin')) {
                this.items.push({ title: 'H-1B Compliance / New work site request', col: 1, body: 'H1B Compliance / New work site request', avatar: 'school', redirectUrl: "lcaHomes" });
                this.items.push({ title: 'LCA NWS External Replicator', col: 2, body: 'LCA NWS External Replicator', avatar: 'school', redirectUrl: "lcaHome" });
                this.items.push({ title: 'H-1B Compliance Internal Tracker', col: 3, body: 'H1B Compliance Internal Tracker', avatar: 'school', redirectUrl: "internalLca" });
              }
              if (this.isSuperAdmin.includes('LCACompliance') && this.AdminRoles.includes('LCAComplianceExternalReplicator')) {
                this.items.push({ title: 'LCA NWS External Replicator', col: 1, body: 'LCA NWS External Replicator', avatar: 'school', redirectUrl: "lcaHome" });       
              }
              if (this.isSuperAdmin.includes('LCACompliance') && this.AdminRoles.includes('LCAComplianceInternal')) {
                this.items.push({ title: 'H-1B Compliance Internal Tracker', col: 3, body: 'H1B Compliance Internal Tracker', avatar: 'school', redirectUrl: "internalLca" });
              }
              if (this.isSuperAdmin.includes('LCACompliance') && this.AdminRoles.includes('LCAComplianceExternal')) {
                this.items.push({ title: 'H-1B Compliance / New work site request', col: 1, body: 'H1B Compliance / New work site request', avatar: 'school', redirectUrl: "lcaHomes" });
              }
              if (this.isSuperAdmin.includes('DigitalAssessmentForm')) {
                this.items.push({ title: 'Digital Assessment Form', col: 2, body: 'Digital Assessment form', avatar: 'home', redirectUrl: "daf/dashboard" });
              }
              //if(this.isSuperAdmin.includes('LCACompliance')&&!this.AdminRoles.includes('LCAComplianceInternal')&&!this.AdminRoles.includes('LCACompliance-Admin')&&!this.AdminRoles.includes('LCAComplianceExternalReplicator')){
              //  this.items=this.items.filter(item=>item.title!="H-1B Compliance Internal Tracker");
              //  }
             if(this.isSuperAdmin.length==0){
              this.items=[];
             }
            }
            if(this.items.length>0){
              this.items.forEach((element,index) => {
              if(index<3){
                element.col=index+1;
              }
              else if(index<6){
                element.col=index-2;
              }
              else{
                element.col=index-5;
              }
              });
            }
                this.isPageReady=true;

          }
        });

  }
  logout() {
    this.commonService.logoutSession().subscribe((data: any) => {
      this.authService.logout();
    });
  }
}
